<template>
  <div>Signing out...</div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';

export default defineComponent({
  name: 'SignOut',
  components: {
  },
  setup() {
    const router = useRouter();

    store.dispatch('signOut').then(() => {
      router.push('/');
    });
  },
});
</script>
