import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';

function createRoute(path, name, component) {
  return {
    path,
    name,
    component,
    meta: {
      guest: false,
    },
  };
}
function createGuestRoute(path, name, component) {
  return {
    path,
    name,
    component,
    meta: {
      guest: true,
    },
  };
}

const routes = [
  createGuestRoute('/', 'Home', Home, true),
  createGuestRoute('/about', 'About', () => import(/* webpackChunkName: "about" */ '../views/About.vue')),
  createGuestRoute('/sign-in', 'SignIn', () => import(/* webpackChunkName: "signin" */ '../views/SignIn.vue')),
  createGuestRoute('/sign-out', 'SignOut', () => import(/* webpackChunkName: "signout" */ '../views/SignOut.vue')),

  createRoute('/dashboard', 'Dashboard', () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')),
  createRoute('/sites', 'Sites', () => import(/* webpackChunkName: "sites" */ '../views/sites/Sites.vue')),
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const guest = to.matched.some((route) => route.meta.guest);
  if (guest) {
    if (to.path === '/sign-in' && store.getters.isLoggedIn) {
      if (to.query && to.query.return) {
        next({ path: to.query.return });
      } else {
        next({ path: '/dashboard' });
      }
    } else {
      next();
    }
  } else if (store.getters.isLoggedIn) {
    next();
  } else {
    next({ path: '/sign-in' });
  }
});

export default router;
