import { createApp } from 'vue';
import axios from 'axios';
import { DateTime } from 'luxon';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';

function refreshToken(vueStore) {
  if (vueStore.state.auth.isRefreshing) {
    return vueStore.state.auth.refreshingCall;
  }
  vueStore.commit('setAuthRefreshingState', true);
  const refreshingCall = axios.post('auth/refresh', {}, { withCredentials: true })
    .then((res) => {
      if (res.data && res.data.success && res.data.result) {
        vueStore.commit('login', res.data.result);
      }
      vueStore.commit('setAuthRefreshingState', false);
      vueStore.commit('setAuthRefreshingCall', undefined);
      return Promise.resolve(true);
    });
  vueStore.commit('setAuthRefreshingCall', refreshingCall);
  return refreshingCall;
}

async function requestOnFulfilled(config) {
  // Set the base url for api requests
  // eslint-disable-next-line no-param-reassign
  config.baseURL = process.env.VUE_APP_API_URL;
  // Set the Auth token
  if (store.state.token) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: store.state.token,
    };
  }
  // eslint-disable-next-line no-param-reassign
  config.rawData = config.data;
  return config;
}
function requestOnError(error) {
  return Promise.reject(error);
}

function responseOnError(error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && (originalRequest.url.indexOf('auth/refresh') >= 0)) {
    store.dispatch('signOut').then(() => {
      router.push('/sign-in');
    });
    return Promise.reject(error);
  // eslint-disable-next-line no-else-return
  } else {
    if (error.response.status === 401 && store.getters.isLoggedIn && !originalRequest.isRetry) {
      originalRequest.isRetry = true;
      return refreshToken(store)
        // eslint-disable-next-line no-unused-vars
        .then((_) => {
          originalRequest.data = originalRequest.rawData;
          originalRequest.headers.Authorization = store.state.token;
          return axios.request(originalRequest);
        });
    }
    return Promise.reject(error);
  }
}

axios.interceptors.request.use(requestOnFulfilled, requestOnError);
axios.interceptors.response.use((response) => response, responseOnError);

const app = createApp(App)
  .use(store)
  .use(router);

app.config.globalProperties.$filters = {
  dateMed(value) {
    const dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATE_MED);
  },
  dateLon(value) {
    const dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATE_FULL);
  },
  contentDescription(value) {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    return '';
  },
  siteStatus(value) {
    let returnValue = 'None';
    switch (value) {
      case 0:
        returnValue = 'Disabled';
        break;
      case 2:
        returnValue = 'Active';
        break;
      case 3:
        returnValue = 'Archive';
        break;
      default:
    }
    return returnValue;
  },
  imageUrl(value) {
    return `${process.env.VUE_APP_BLOB_CONTAINER}/images/${value}`;
  },
};

app.mount('#app');
