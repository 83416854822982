<template>
  <div class="min-h-full">
    <NavBar></NavBar>
    <main class="lg:pl-64 flex-1 pb-8">
      <div class="mt-8">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-lg leading-6 font-medium text-gray-900">Navigation</h2>
          <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <!-- Card -->
            <div v-for="item in navigation" :key="item.name" class="bg-white overflow-hidden shadow rounded-lg">
              <div class="p-5">
                <router-link :to="item.href" class="text-blue hover:text-blue-dark">
                  <component :is="item.icon" class="h-32 w-32 mx-auto" aria-hidden="true" />
                </router-link>
              </div>
              <div class="bg-gray-50 px-5 py-3">
                <div class="text-sm">
                  <router-link :to="item.href" class="font-bold text-blue hover:text-blue-dark">
                    {{item.name}}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  defineComponent,
} from 'vue';
import {
  useNavigation,
} from '@/composables/useNavigation';
import NavBar from '@/components/NavBar.vue';

export default defineComponent({
  components: {
    NavBar,
  },
  setup() {
    const { navigation, setCurrent } = useNavigation();
    setCurrent('/dashboard');

    return {
      navigation,
    };
  },
});
</script>
