import { reactive } from 'vue';
import axios from 'axios';
import responseHelper from '../helpers/response.helper';
import { contentStatuses } from '../lib/constants';

// constants
const siteTypeOptions = [
  { name: 'Link', value: 1 },
  { name: 'Popup', value: 2 },
];
const siteStatusOptions = [
  { name: 'Disabled', value: contentStatuses.DISABLED },
  { name: 'Active', value: contentStatuses.LIVE },
  { name: 'Archive', value: contentStatuses.FINISHED },
];

// shared state object
const sitesState = reactive({
  loading: false,
  sites: [],
});

// data functions
const searchSites = async () => {
  sitesState.loading = true;
  sitesState.sites = [];
  const response = await axios.get('sites');
  sitesState.sites = response.data.result;
  sitesState.loading = false;
};
const searchSitesArchive = async () => {
  sitesState.loading = true;
  sitesState.sites = [];
  const response = await axios.get('sites/archive');
  sitesState.sites = response.data.result;
  sitesState.loading = false;
};

// eslint-disable-next-line import/prefer-default-export
export function useSites() {
  const createSiteState = reactive({
    submitted: false,
    result: undefined,
    error: responseHelper.noError,
    form: {
      title: '',
      description: '',
      linkUrl: '',
      siteType: siteTypeOptions[0].value,
      sortOrder: 1,
      status: 0,
    },
  });

  const createSite = async () => {
    if (!createSiteState.submitted) {
      createSiteState.submitted = true;
      createSiteState.result = undefined;
      createSiteState.error = responseHelper.noError;
      try {
        const response = await axios.post('sites', createSiteState.form);
        createSiteState.result = response.data.result;
        createSiteState.submitted = false;
        return createSiteState.result;
      } catch (err) {
        createSiteState.error = responseHelper.getMessage(err);
        createSiteState.submitted = false;
      }
    }
    return undefined;
  };

  const editSiteState = reactive({
    loading: true,
    updating: false,
    successMessage: '',
    loadError: responseHelper.noError,
    updateError: responseHelper.noError,
    form: undefined,
  });

  const loadSiteForEdit = async (recordId) => {
    try {
      editSiteState.loading = true;
      editSiteState.loadError = responseHelper.noError;
      const response = await axios.get(`sites/${recordId}`);
      if (response?.data?.success && response?.data?.result) {
        editSiteState.form = response.data.result;
        editSiteState.loading = false;
      } else {
        editSiteState.loadError = responseHelper.loadingUnexpectedResult;
      }
    } catch (err) {
      editSiteState.loadError = responseHelper.loadingUnexpectedResult;
    }
  };
  const updateSite = async (recordId) => {
    try {
      if (!editSiteState.updating) {
        editSiteState.updating = true;
        editSiteState.updateError = responseHelper.noError;
        editSiteState.successMessage = '';
        const response = await axios.put(`sites/${recordId}`, editSiteState.form);
        if (response?.data?.success && response?.data?.result) {
          editSiteState.successMessage = response.data.result.message;
        } else {
          editSiteState.updateError = responseHelper.unexpectedResult;
        }
        editSiteState.updating = false;
      }
    } catch (err) {
      editSiteState.updateError = responseHelper.getMessage(err);
      editSiteState.updating = false;
    }
  };

  return {
    siteTypeOptions,
    siteStatusOptions,
    sitesState,
    createSiteState,
    editSiteState,
    searchSites,
    searchSitesArchive,
    createSite,
    loadSiteForEdit,
    updateSite,
  };
}
