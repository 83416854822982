import { createStore } from 'vuex';
import axios from 'axios';
import { storeKeys } from '../lib/constants';

function initToken() {
  return localStorage.getItem(storeKeys.TOKEN) || null;
}
function initProfile() {
  if (localStorage.getItem(storeKeys.PROFILE)) {
    JSON.parse(localStorage.getItem(storeKeys.PROFILE));
  }
  return null;
}

export default createStore({
  state: {
    token: initToken(),
    profile: initProfile(),
    auth: {
      isRefreshing: false,
      refreshingCall: undefined,
    },
  },
  mutations: {
    login(state, result) {
      // Set the token
      state.token = result.token;
      state.profile = result.profile;
      localStorage.setItem(storeKeys.TOKEN, result.token);
      localStorage.setItem(storeKeys.PROFILE, JSON.stringify(result.profile));
    },
    setSignOut(state) {
      // Clear the token
      state.token = null;
      state.profile = null;
      localStorage.removeItem(storeKeys.TOKEN);
      localStorage.removeItem(storeKeys.PROFILE);
    },
    setAuthRefreshingCall(state, payload) {
      state.auth.refreshingCall = payload;
    },
    setAuthRefreshingState(state, payload) {
      state.auth.isRefreshing = payload;
    },
    setAvatar(state, payload) {
      if (state.profile) {
        state.profile.imageUrl = payload;
        localStorage.setItem(storeKeys.PROFILE, JSON.stringify(state.profile));
      }
    },
  },
  actions: {
    async signOut(context) {
      context.commit('setSignOut');
      axios.post('auth/logout', null, {
        withCredentials: true,
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  modules: {
  },
  getters: {
    isLoggedIn(state) {
      if (state.token) {
        return true;
      }
      return false;
    },
    getUsername(state) {
      if (state.profile && state.profile.username) {
        return state.profile.username;
      }
      return '';
    },
    getAvatar(state) {
      if (state.profile && state.profile.imageUrl) {
        // eslint-disable-next-line prefer-template
        return process.env.VUE_APP_BLOB_CONTAINER + '/images/' + state.profile.imageUrl;
      }
      return '';
    },
    getFirstName(state) {
      if (state.profile && state.profile.firstName) {
        return state.profile.firstName;
      }
      return '';
    },
    getLastName(state) {
      if (state.profile && state.profile.lastName) {
        return state.profile.lastName;
      }
      return '';
    },
    getEmail(state) {
      if (state.profile && state.profile.email) {
        return state.profile.email;
      }
      return '';
    },
  },
});
