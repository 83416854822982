<template>
  <!-- <NavBar></NavBar> -->
  <router-view/>
  <!-- <Footer></Footer>
  <Notification></Notification> -->
</template>

<script>
// import NavBar from '@/components/NavBar.vue';
// import Footer from '@/components/Footer.vue';
// import Notification from '@/components/Notification.vue';

export default {
  components: {
    // NavBar,
    // Footer,
    // Notification,
  },
  setup() {
  },
};
</script>

<style>
</style>
