<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="sidebarOpen = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
        <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-blue-dark">
          <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <nav class="flex-shrink-0 h-full divide-y divide-gray-700 overflow-y-auto" aria-label="Sidebar">
            <div class="px-2 space-y-1">
              <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-blue text-white' : 'text-white hover:text-white hover:bg-blue', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
                <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </div>
            <div class="mt-6 pt-6">
              <div class="px-2 space-y-1">
                <router-link v-for="item in secondaryNavigation" :key="item.name" :to="item.href" class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-white hover:text-white hover:bg-blue">
                  <component :is="item.icon" class="mr-4 h-6 w-6 text-white" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </div>
            </div>
          </nav>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- sidebar for desktop -->
  <div class="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
    <div class="flex flex-col flex-grow bg-blue-dark pt-5 pb-4 overflow-y-auto">
      <nav class="flex-1 flex flex-col divide-y divide-gray-700 overflow-y-auto" aria-label="Sidebar">
        <div class="px-2 space-y-1">
          <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-blue text-white' : 'text-white hover:text-white hover:bg-blue', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
            <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
            {{ item.name }}
          </router-link>
        </div>
        <div class="mt-6 pt-6">
          <div class="px-2 space-y-1">
            <router-link v-for="item in secondaryNavigation" :key="item.name" :to="item.href" class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white hover:text-white hover:bg-blue">
              <component :is="item.icon" class="mr-4 h-6 w-6 text-white" aria-hidden="true" />
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="lg:pl-64 flex flex-col flex-1">
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none lg:hidden">
      <button type="button" class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <MenuAlt1Icon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
} from 'vue';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  MenuAlt1Icon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  useNavigation,
} from '@/composables/useNavigation';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt1Icon,
    XIcon,
  },
  setup() {
    const { navigation, secondaryNavigation, sidebarOpen } = useNavigation();

    return {
      navigation,
      secondaryNavigation,
      sidebarOpen,
    };
  },
});
</script>
