<template>
  <div class="min-h-full">
    <NavBar></NavBar>

    <main class="lg:pl-64 flex-1 pb-8">
      <Breadcrumbs :pages="pages"></Breadcrumbs>
      <div class="mt-8">

        <div class="md:flex md:items-center md:justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex-1 min-w-0">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Sites
            </h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <router-link to="/sites/create" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create
            </router-link>
          </div>
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-5">
          <div class="block">
            <nav class="flex space-x-4" aria-label="Tabs">
              <router-link v-for="tab in tabs" :key="tab.name" :to="tab.href" :class="[tab.name === currentTab ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800', 'px-3 py-2 font-medium text-sm rounded-md']" :aria-current="tab.current ? 'page' : undefined">
                {{ tab.name }}
              </router-link>
            </nav>
          </div>
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="bg-white shadow overflow-hidden sm:rounded-md mt-5">
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="item in sitesState.sites" :key="item.id">
                <router-link :to="`/sites/edit/${item.id}`" class="block hover:bg-gray-50">
                  <div class="px-4 py-4 flex items-center sm:px-6">
                    <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div class="truncate">
                        <div class="flex text-sm">
                          <p class="font-bold text-bluep-dark truncate"><span class="text-blue ">{{ item.title }}</span></p>
                        </div>
                      </div>
                      <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                        <div class="flex overflow-hidden -space-x-1">
                          <!-- <img v-for="applicant in position.applicants" :key="applicant.email" class="inline-block h-6 w-6 rounded-full ring-2 ring-white" :src="applicant.imageUrl" :alt="applicant.name" /> -->
                        </div>
                      </div>
                    </div>
                    <div class="ml-5 flex-shrink-0">
                      <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import {
  ChevronRightIcon,
} from '@heroicons/vue/solid';
import { useNavigation } from '@/composables/useNavigation';
import { useSites } from '@/composables/useSites';
import NavBar from '@/components/NavBar.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

const pages = [
  { name: 'Sites', href: '/sites', current: true },
];

const tabs = [
  { name: 'Current', href: '/sites' },
  { name: 'Archive', href: '/sites/archive' },
];

export default defineComponent({
  components: {
    ChevronRightIcon,
    NavBar,
    Breadcrumbs,
  },
  setup() {
    const { setCurrent } = useNavigation();
    setCurrent('/sites');

    const { sitesState, searchSites } = useSites();

    onMounted(() => {
      searchSites().catch((error) => {
        console.log(error);
      });
    });

    const currentTab = ref(tabs[0].name);

    return {
      sitesState,
      pages,
      tabs,
      currentTab,
    };
  },
});
</script>
