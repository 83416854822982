import {
  ref,
  reactive,
} from 'vue';
import {
  AdjustmentsIcon,
  HomeIcon,
} from '@heroicons/vue/outline';
import {
  LogoutIcon,
} from '@heroicons/vue/solid';

const navigation = reactive([
  {
    name: 'Dashboard', href: '/dashboard', icon: AdjustmentsIcon, current: true,
  },
  {
    name: 'Sites', href: '/sites', icon: HomeIcon, current: false,
  },
]);
const secondaryNavigation = [
  {
    name: 'Sign Out', href: '/sign-out', icon: LogoutIcon,
  },
];
const sidebarOpen = ref(false);

function setCurrent(current) {
  if (current) {
    for (let i = 0; i < navigation.length; i += 1) {
      if (navigation[i].href === current && !navigation[i].current) {
        navigation[i].current = true;
      } else if (navigation[i].href !== current && navigation[i].current) {
        navigation[i].current = false;
      }
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export function useNavigation() {
  return {
    setCurrent,
    sidebarOpen,
    navigation,
    secondaryNavigation,
  };
}
