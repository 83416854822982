const noError = { message: '', errors: [] };
const unexpectedResult = { message: 'Unexpected result', errors: [{ param: 'result', error: 'Please try again. If this issue continues then please contact technical support.' }] };
const loadingUnexpectedResult = { message: 'Failed to load', errors: [{ param: 'result', error: 'There was an unexpected result whilst loading the screen. Please try again. If this issue continues then please contact technical support.' }] };

function getStatusCode(error) {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }
  return -1;
}

function getMessage(error) {
  if (error?.response?.data?.success === false) {
    return error.response.data;
  }
  if (error?.response?.data?.message && error?.response?.data?.errors) {
    return error.response.data;
  }
  return unexpectedResult;
}

function createError(message, errors) {
  const error = {
    message,
    errors: [],
  };

  if (errors && errors.length > 0) {
    errors.forEach((err) => {
      error.errors.push({ param: 'result', error: err });
    });
  }

  return error;
}

export default {
  noError,
  unexpectedResult,
  loadingUnexpectedResult,

  getMessage,
  getStatusCode,
  createError,
};
